import { defineComponent } from "@vue/composition-api";
import CloseEventForm from "@events/components/CloseEventForm.vue";
import CloseEventButtons from "@events/components/CloseEventButtons.vue";
import { useCloseEvent } from "@events/composable/close-event";
import { formatDate } from "@/utils/date";
import { useActivateEvent } from "./activate-event";
import { useSelectedEvent } from "./selected-event";
export default defineComponent({
    name: "ActivateEvent",
    components: {
        CloseEventForm,
        CloseEventButtons,
    },
    setup() {
        const { eventClosingStatus, setEventClosingType, clearEventClosingType } = useCloseEvent();
        const { event, eventNotFound, eventDownloading, eventIsCreated } = useSelectedEvent();
        const { activateEvent, eventActivating, eventIsActivated } = useActivateEvent({ event });
        return {
            event,
            eventNotFound,
            eventIsCreated,
            eventDownloading,
            eventClosingStatus,
            setEventClosingType,
            clearEventClosingType,
            activateEvent,
            eventActivating,
            eventIsActivated,
            formatDate,
        };
    },
});
