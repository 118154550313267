import { computed, ref } from "@vue/composition-api";
import { useRedirect } from "@/composable/use-redirect";
import { activateEvent as serviceActivateEvent } from "@/services/event/activate-event";
export function useActivateEvent({ event }) {
    const { redirect } = useRedirect();
    const eventIsActivated = ref(false);
    const activateEvent = async () => {
        if (event.value === null)
            return;
        const updatedEvent = await serviceActivateEvent.run(event.value.id);
        eventIsActivated.value = true;
        await redirect("active-event", { id: updatedEvent.id });
    };
    return {
        eventActivating: serviceActivateEvent.active,
        eventIsActivated: computed(() => eventIsActivated.value),
        activateEvent,
    };
}
