import { Event } from "@/models/Event";
import { ApiNotFoundError } from "@/services/api";
import { fail, success } from "@/utils/either";
import { fetchEvent } from "./api";
export async function fetchAndTransformEvent(eventId) {
    const result = await fetchEvent(eventId);
    if (result.isOk()) {
        return success(new Event(result.value));
    }
    else {
        if (result.value instanceof ApiNotFoundError) {
            return fail(null);
        }
        else {
            throw result.value;
        }
    }
}
