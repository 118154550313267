import { computed, ref, watch } from "@vue/composition-api";
import { getEvent } from "@/services/event/get-event";
import useRoute from "@/composable/use-route";
export function useSelectedEvent() {
    const route = useRoute();
    const event = ref(null);
    const eventId = computed(() => Number(route.value.params.id));
    const eventNotFound = ref(false);
    const eventIsCreated = computed(() => event.value !== null && event.value.status === "created");
    const downloadEvent = async () => {
        eventNotFound.value = false;
        const result = await getEvent.run(eventId.value);
        if (result.isOk()) {
            event.value = result.value;
        }
        else {
            eventNotFound.value = true;
        }
    };
    watch(eventId, downloadEvent, { immediate: true });
    return {
        eventDownloading: getEvent.active,
        event: computed(() => event.value),
        eventNotFound: computed(() => eventNotFound.value),
        eventIsCreated,
    };
}
